<template>
  <div :style="slidePosition" class=" w-100 h-100">
    <div class="position-absolute d-flex flex-column justify-content-center align-items-center  h-100"
      style="top: 0%; left: 10%;width:40%;">
      <img src="images/tree.png" class=" z-3 tree" style="width: 100%;transform:translateY(50%);" />
      <inline-svg src="images/Slide6/half-circle.svg" class="z-2 circle5-a text-warning"
        style="transform:scale(0); margin-top:-9vh;height: 2.5%;" />
      <inline-svg src="images/Slide6/half-circle.svg" class="z-4 circle5-b text-warning"
        style="margin-top:-0.01vh;height: 2.5%;transform: rotate(180deg)scale(0);" />
      <inline-svg src="images/Slide6/half-circle.svg" class="z-2 circle5-a text-warning"
        style="transform:scale(0); margin-top:-3vh;height: 2.5%;" />
      <inline-svg src="images/Slide6/half-circle.svg" class="z-4 circle5-b text-warning"
        style="margin-top:-0.01vh;height: 2.5%;transform: rotate(180deg) scale(0);" />
      <inline-svg src="images/Slide6/half-circle.svg" class="z-2 circle5-a text-warning"
        style="transform:scale(0); margin-top:-3vh;height: 2.5%;" />
      <inline-svg src="images/Slide6/half-circle.svg" class="z-4 circle5-b text-warning"
        style="margin-top:-0.01vh;height: 2.5%;transform: rotate(180deg) scale(0);" />
    </div>
    <div class="position-absolute d-flex flex-column justify-content-center align-items-start  h-100"
      style="top: 0%; left: 55%;width:37.5%;">
      <h6 class="text-warning font-rem-2 font-vw-3-5 font-w-600 fade5 mt-4" style="opacity:1; ">
        1969
      </h6>
      <h6 class="text-warning font-rem-2 font-vw-3-5 font-w-600 fade5 my-0" style="opacity:1; ">
        A NOD TO NEWTON
      </h6>
      <p class="pt-3 text-5 text-warning font-vw-1-25" style="opacity:1; ">
        On May 5, 1969, TRIUMF’s official opening ceremony included the planting
        of apple trees that are direct descendants from the ones whose falling
        apples, legend has it, inspired Sir Isaac Newton’s thinking on the
        nature of gravity. Located in the traffic circle in front of TRIUMF, the
        Newtonian apple trees are still bearing fruit today—a symbol of TRIUMF’s
        ongoing quest to advance our fundamental understanding of universal
        forces and particles.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  methods: {
    tree(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      animation.add({
        targets: ".tree",
        scale: [0, 1],
        opacity: [0, 1],
        translateY: ["35%", "0%"],
        duration: 1000,
        delay: 4000,
        easing: "easeInQuart"
      })
      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * 1
      )
    },
    fade5(percentage) {
      let animation = anime
        .timeline({
          autoplay: false,
          loop: false,
          endDelay: 0
        })
        .add({
          targets: ".fade5",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 200,
          easing: "easeInOutCubic",
          delay: (el, i) => 6000 + 50 * i
        })
        .add({
          targets: ".text-5",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 200,
          easing: "easeInOutCubic"
        })
      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * (1 - percentage)
      )
    },
    circles(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })
      let animation2 = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })
      animation.add({
        targets: ".circle5-a",
        scale: [0, 1],
        translateY: ["150%", "0%"],
        opacity: [0, 1],
        duration: 1000,
        delay: (el, i) => 4000 + 400 * i,
        easing: "easeInQuart"
      })
      animation2.add({
        targets: ".circle5-b",
        scale: [0, 1],
        translateY: ["100%", "0%"],
        opacity: [0, 1],
        duration: 1000,
        delay: (el, i) => 4000 + 400 * i,
        easing: "easeInQuart"
      })
      animation.seek(
        percentage <= 0.5
          ? animation.duration * (2 * percentage)
          : animation.duration * 1
      )
      animation2.seek(
        percentage <= 0.5
          ? animation2.duration * (2 * percentage)
          : animation2.duration * 1
      )
    } /* ,
    circleLine1(percentage) {
      let circleLine1 = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 0
      })

      circleLine1.add({
        targets: ".circle-line-1",
        scale: [1, 0],
        duration: 200,
        delay: 1000,
        easing: "easeInOutCubic"
      })

      circleLine1.seek(percentage > 0.5 ? circleLine1.duration * percentage : 0)
    } */
  },
  watch: {
    innerPosition() {
      this.tree(this.innerPosition)
      this.circles(this.innerPosition)
      this.fade5(this.innerPosition)
      /* this.circleLine1(this.innerPosition) */
    }
  }
}
</script>

<style></style>
